<template>
  <div class="feedback-page">
    <v-row justify="center">
      <v-col md="6">

        <intract-smart-list v-if="showList" disable-search paginated ref="feedback-smart-list" class="mt-4"
        :endpoint="feedbackEndpoint" :item-options="{'title': 'title'}">
            <template v-slot:list-item="{ item: feedbackItem }">
                <div class="posts-container">
                <v-card class="my-2 mx-2 post-card">
                    <v-list-item>
                      <v-list-item-avatar>
                          <v-img :src="feedbackItem.user.image"> </v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                          <v-list-item-title> {{ feedbackItem.user.full_name }} </v-list-item-title>
                          <v-list-item-subtitle> {{ feedbackItem.user.username }} | {{ feedbackItem.room.sections }} </v-list-item-subtitle>
                      </v-list-item-content>
                      <p class="my-0 caption grey--text">
                          {{ moment(feedbackItem.created).format("hh:mmA, Do MMM") }}
                      </p>

                        <!-- <intract-drop-down-menu 
                          v-if="currentUser.is_student"
                          :options-list="getDropdownOptions(feedbackItem)"
                        /> -->
                    </v-list-item> 

                    <v-card-text class="pb-3 pt-0">
                        <div class="black--text subtitle-1"
                        v-html="TextHelper.linkify(feedbackItem.description)"
                        ></div>
                        <v-img
                          v-if="feedbackItem.image_file"
                          :src="feedbackItem.image_file['document']"
                        ></v-img>

                        <v-chip v-if="feedbackItem.subject.name" small color="green lighten-4" class="mt-2">
                          {{ feedbackItem.subject.name }} | {{feedbackItem.room.sections}} 
                        </v-chip>
                    </v-card-text>

                </v-card>
                </div>
            </template>       
        </intract-smart-list>      
      </v-col>
    </v-row>

    <v-fab-transition>
      <v-btn
        v-if="!isLoading && currentUser.is_student"
        color="primary"
        class="non-focused"
        @click="feedbackCreateEditSheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <intract-create-edit-sheet
      persistent
      v-if="showList && currentUser.is_student"
      title="Send Feedback"
      :visible="feedbackCreateEditSheet.visible"
      @close="feedbackCreateEditSheet.visible = false"
      :fields="formFields"
      enable-media
      :endpoint="endpoints.feedbackViewSet"
      :edit-id="feedbackCreateEditSheet.editId"
      :edit-success-message="feedbackCreateEditSheet.editSuccessMessage"
      :data-object="feedbackCreateEditSheet.obj"
      :create-success-message="feedbackCreateEditSheet.createSuccessMessage"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (feedbackCreateEditSheet.obj = obj)"
    />

    <choose-room-dialog
      :visible="showRoomDialog"
      @roomSelected="
        (r) => {
          selectedRoom = r;
          showRoomDialog = false;
        }
      "
    />

  </div> 
</template>

<script>
import TextHelper from '@utils/text_helper';
import Mixins from "@utils/mixins";
import { mapGetters } from "vuex";
import moment from "moment";
// import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractCreateEditSheet, { CreateEditFieldTypes,} from "@components/generics/IntractCreateEditSheet.vue";
import ChooseRoomDialog from "@components/dialogs/ChooseRoomDialog";

export default {
    name: "Feedback",
    mixins: [Mixins.essentials, Mixins.pullToRefresh],

    components: {
        IntractSmartList,
        ChooseRoomDialog,
        IntractCreateEditSheet,
        // IntractDropDownMenu
    }, 

    data () {
        return {
            TextHelper,
            selectedRoom: null,
            showRoomDialog: false,
            moment,
            // showDeleteDialog: false, 
            // toBeDeletedItem: null,
            feedbackCreateEditSheet: {
                visible: false,
                createSuccessMessage: "Feedback sent successfully!",
                editId: null,
                obj: {
                    room: null,
                    description: "",
                    institution: null,
                    user: null, 
                },
            }            
        }
    }, 

    computed: {
        ...mapGetters(["currentUser", "currentInstitution"]), 

        showList() {
          return (this.currentUser.is_admin && this.selectedRoom) || this.currentUser.is_student || (this.currentUser.is_faculty && !this.currentUser.is_admin);
        }, 

        feedbackEndpoint() {

            if (!this.showList) return null; 
            if (this.currentUser.is_student){
                return this.Helper.addUrlParams(this.endpoints.feedbackViewSet, ["user=" + this.currentUser.id, "ordering=-created"]);
            }
            else if (this.currentUser.is_admin) {
                return this.Helper.addUrlParams(this.endpoints.feedbackViewSet, ["room=" + this.selectedRoom?.id, "ordering=-created"]);
            }
            else if (this.currentUser.is_faculty){
                // console.log(this.endpoints.feedbackViewSet + "faculty/")
                return this.Helper.addUrlParams(this.endpoints.feedbackViewSet + "faculty/", ["ordering=-created"]); 
            }
            else return null;
        }, 

        subjectsEndpoint() {
            return this.Helper.addUrlParams(
              this.endpoints.subjectViewSet,
              "room=" + this.currentUser.room.id); 
        }, 
      
        formFields() {
          return {
            subject: {
              fieldType: CreateEditFieldTypes.SELECT,
              label: "Subject",
              md: 12,
              required: false,
              clearable: true,
              endpoint: this.subjectsEndpoint,
              items: [],
              itemText: "name",
              itemValue: "id",
            },
            description: {
              fieldType: CreateEditFieldTypes.TEXTAREA,
              label: "Description",
              md: 12,
              required: true,
              max: 3000,
            },
            image_file: {
              fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
              label: "Upload Image",
              helper: "JPG, PNG, GIF, etc",
              required: false,
            },
          };
        },

    }, 

    methods: {  
        async onRefresh(){
            var el = this.$refs["feedback-smart-list"];
            if (el) el.getItems();
        }, 

      // getDropdownOptions(feedbackItem) {
      //   var self = this;
      //   return [
      //     {
      //       value: 1, 
      //       text: "Edit", 
      //       icon: "mdi-pencil",
      //       type: "edit",
      //       action() {
      //         self.feedbackCreateEditSheet.editId = feedbackItem.id;
      //         self.feedbackCreateEditSheet.visible = true;
      //       }                  
      //     }, 
      //   ];
      // },
    }, 


    created() {
        this.showRoomDialog = this.currentUser.is_admin;
        if (this.currentUser.is_student){
            // console.log(this.currentUser.room.id)
            this.feedbackCreateEditSheet.obj.institution = this.currentInstitution.id;
            this.feedbackCreateEditSheet.obj.user = this.currentUser.id;
            this.feedbackCreateEditSheet.obj.room = this.currentUser.room.id;
        }
    }, 

}
</script>

<style>

</style>
